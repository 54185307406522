




















































































import ViewModel from '@/views/contact/Alliance.ts';
export default ViewModel;
